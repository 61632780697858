
import { createRouter, createWebHistory } from 'vue-router'
import authRouter from '../modules/auth/router'
import dashboardRouter from '../modules/dashboard/router'
import isAuthenticatedGuard from '../modules/auth/guard/auth-guard'

const routes = [
  {
    path: '/',
    ...authRouter
  },
  {
    path: '/dashboard',
    beforeEnter: [ isAuthenticatedGuard ],
    ...dashboardRouter
  },
  { 
    path: '/:pathMatch(.*)*', 
    name: 'NotFound', 
    component: () => import(/* webpackChunkName: "not-found" */ '../views/NotFound'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

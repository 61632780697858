

export default {
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "dasboard-layout" */ '@/modules/dashboard/layouts/DashboardLayout'),
    children: [
        {
            path: 'dt-report-1',
            name: 'dt-report-1',
            component: () => import(/* webpackChunkName: "dasboard-dt-report-1" */ '@/modules/dashboard/views/DTReport1View'),
        },
        {
            path: 'dt-report-2',
            name: 'dt-report-2',
            component: () => import(/* webpackChunkName: "dasboard-dt-report-2" */ '@/modules/dashboard/views/DTReport2View'),
        },
        {
            path: 'dt-report-3',
            name: 'dt-report-3',
            component: () => import(/* webpackChunkName: "dasboard-dt-report-3" */ '@/modules/dashboard/views/DTReport3View'),
        },
        {
            path: 'dt-report-4',
            name: 'dt-report-4',
            component: () => import(/* webpackChunkName: "dasboard-dt-report-4" */ '@/modules/dashboard/views/DTReport4View'),
        },
        {
            path: 'setting',
            name: 'dashboard-setting',
            component: () => import(/* webpackChunkName: "dasboard-test" */ '@/modules/dashboard/views/SettingView'),
        }
    ]
}
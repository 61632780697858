
export default () => ({
    status: 'authenticating', // 'authenticating', 'authenticated', 'not-authenticated'
    accessToken: null,
    origin: null,
    user: null,
    grcConfig: {
        enable: true,
        siteKey: '6LfXKEkkAAAAAA7b6XqIfAb8uCCe4pRNbGJyxjsB'
    }
})


import store from '@/store'

const isAuthenticatedGuard = async (to, from, next) => {
    const accessToken = localStorage.getItem('accessToken')

    if ( accessToken ) {
        if ( accessToken === store.state.accessToken && store.state.user && store.state.origin ) {
            console.log('[Auth Guard] Authentication status: Authenticated')
            next()
        } else {
            const { status } = await store.dispatch('authStore/checkValidAccessToken', accessToken)
            
            if ( status ) {
                console.log('[Auth Guard] Access Token validation status: Is valid')
                next()
            } else { 
                console.log('[Auth Guard] Access Token validation status: Is not valid')
                next({ name: 'welcome' }) 
            }
        }
        
    } else { 
        console.log('[Auth Guard] Authentication status: Not authenticated')
        next({ name: 'welcome' }) 
    }
}

export default isAuthenticatedGuard
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import VCalendar from 'v-calendar'
import 'v-calendar/style.css'

import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
const SwalOptions = { 
    confirmButtonColor: '#0d6efd', 
    cancelButtonColor: '#6c757d',
    denyButtonColor: '#dc3545'
}

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

import './css/styles.scss'
import './assets/bootstrap-icons/font/bootstrap-icons.css'

createApp(App)
    .use(VCalendar, {})
    .use(VueSweetalert2, SwalOptions)
    .use(store)
    .use(router)
    .component('v-select', vSelect)
    .mount('#app')

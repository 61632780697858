import { createStore } from 'vuex'
import authStore from "../modules/auth/store"
import dashboardStore from "../modules/dashboard/store"

const store = createStore({
    modules: {
      authStore,
      dashboardStore
    }
})

export default store

import lerpApi from '@/api/lerpApi'

export const initSidebarMenu = async ({ commit }, menuItems) => {
    if (menuItems && typeof(menuItems) === 'object' && menuItems.length > 0) {
        commit('setSidebarMenu', menuItems)
    }
}

export const changeMenuSelected = async ({ commit }, menuItem) => {
    if (menuItem && typeof(menuItem) === 'object') {
        commit('setMenuSelected', menuItem)
        if (menuItem.breadcrump && typeof(menuItem.breadcrump) === 'object' && menuItem.breadcrump.length > 0) {
            commit('setBreadcrump', menuItem.breadcrump)
        }
    }
}

export const changeBreadcrump = async ({ commit }, items) => {
    if (items && typeof(items) === 'object' && items.length > 0) {
        commit('setMenuSelected', items)
    }
}

export const changeWindowWidth = async ({ commit }, size) => {
    if (typeof(size) === 'number') {
        commit('setWindowWidth', size)
    }
}

export const changeResponsiveBreakpoint = async ({ commit }, bp) => {
    if (typeof(bp) === 'string') {
        commit('setResponsiveBreakpoint', bp)
    }
}

export const getRegisteredCompanies = async ({ commit }, accessToken) => {
    try {
        const { data:resp } = await lerpApi.get('lerp/company', {
            params: { at: accessToken }
        })

        if (resp.api === 200) {
            commit('setCompanies', resp.data)
            return { status: true, mssg: resp.mssg }
        }

        commit('setCompanies', [])
        return { status: false, mssg: 'Failed request', errorCode: `API_${resp.api}` }
    } catch (e) {
        commit('setCompanies', [])
        
        if (e.response) {
            console.log(`[API_CODE:${e.response.data.api}] ${e.response.data.mssg}`)
            return { status: false, mssg: 'Failed request', errorCode: `ERROR_${e.response.data.api}` }
        }

        console.log(`[HTTP_CODE:${e.code}] ${e.message}`)
        return { status: false, mssg: 'Failed request', errorCode: `HTTP_${e.code}` }
    }
}

export const clearStateData = async ({ commit }) => {
    commit('clearState')
}
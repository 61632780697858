
export const sidebarMenu = (state) => {
    return state.sidebarMenu
}

export const menuSelected = (state) => {
    return state.menuSelected
}

export const breadcrump = (state) => {
    return state.breadcrump
}

export const windowWidth = (state) => {
    return state.windowWidth
}

export const breakpoints = (state) => {
    return state.breakpoints
}

export const responsive = (state) => {
    return state.responsive
}

export const companies = (state) => {
    return state.companies
}

export const setSidebarMenu = (state, menuItems) => {
    state.sidebarMenu = menuItems
}

export const setMenuSelected = (state, menuItem) => {
    state.menuSelected = menuItem
}

export const setBreadcrump = (state, item) => {
    state.breadcrump = item
}

export const setWindowWidth = (state, size) => {
    state.windowWidth = size
}

export const setResponsiveBreakpoint = (state, bp) => {
    if (state.breakpoints[bp]) {
        state.responsive = state.breakpoints[bp]
    }
}

export const setCompanies = (state, companies) => {
    state.companies = []

    if (companies && companies.length > 0) {
        state.companies = companies
    }
}

export const clearState = (state) => {
    state.companies = []
}
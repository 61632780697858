import { DateTime } from 'luxon'

export const b64EncodeUnicode = (str) => {
    return window.btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) => {
        return String.fromCharCode('0x' + p1)
    }))
}

export const validateEmailFormat = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    
    if (email.match(re)) {
        return true
    }
    
    return false
}

export const validatePhoneChars = (event) => {
    if (event.keyCode !== 8 && !/[0-9\s+]/.test(event.key)) {
        event.preventDefault()
    }
}

export const validateText = (text, long=0) => {
    if (typeof(text) === 'string') {
      if (text.trim() !== '') {
        return long > 0 ? text.length >= long : true
      }
    }
  
    return false
}

export const validateFile = (fileName, allowedTypes=['jpg', 'jpeg', 'png', 'pdf']) => {
    const re = /(?:\.([^.]+))?$/

    if (fileName) {
        const fileExt = re.exec(fileName)[1]

        if (allowedTypes.includes(fileExt)) {
            return true
        }
    }

    return false
}

export const validateDatetime = (datetime, inFmt='yyyy-LL-dd HH:mm:ss') => {
    if (typeof(datetime) === 'object') {
        return DateTime.fromJSDate(datetime).isValid
    } else if (typeof(datetime) === 'string') {
        return DateTime.fromFormat(datetime, inFmt).isValid
    }

    return false
}

export const formatDatetime = (datetime, outFmt='yyyy-LL-dd HH:mm:ss', inFmt='yyyy-LL-dd HH:mm:ss') => {
    if (typeof(datetime) === 'object') {
        const converted = DateTime.fromJSDate(datetime)
        if (converted.isValid) {
            return converted.toFormat(outFmt)
        }
    } else if (typeof(datetime) === 'string') {
        const converted = DateTime.fromFormat(datetime, inFmt)
        if (converted.isValid) {
            return converted.toFormat(outFmt)
        }
    }

    return datetime
}

export const changeDatetimeTimezone = (datetime, currentTimezone, targetTimezone, outFormat='object') => {
    if (typeof(datetime) === 'object') {
        const original = DateTime.fromJSDate(datetime, { zone: currentTimezone })

        if (original.isValid) {
            const result = original.setZone(targetTimezone)

            if (outFormat === 'object') {
                return result
            }

            return result.toFormat(outFormat) 
        }
    } else if (typeof(datetime) === 'string') {
        const original = DateTime.fromJSDate(new Date(datetime), { zone: currentTimezone })

        if (original.isValid) {
            const result = original.setZone(targetTimezone)

            if (outFormat === 'object') {
                return result
            }
            
            return result.toFormat(outFormat)
        }
    }

    return datetime
}

export const getFileSize = (bytes) => {
    if (typeof(bytes) === 'number') {
        if (Math.ceil(bytes / 1024) <= 1024) {
            return `${Math.ceil(bytes / 1024)}KB`
        } else if (Math.ceil(bytes / (1024 * 2)) <= 1024) {
            return `${Math.ceil(bytes / (1024 * 2))}MB`
        } else if (Math.ceil(bytes / (1024 * 3)) <= 1024) {
            return `${Math.ceil(bytes / (1024 * 3))}GB`
        } else if (Math.ceil(bytes / (1024 * 4)) <= 1024) {
            return `${Math.ceil(bytes / (1024 * 4))}TB`
        } else {
            return `${bytes}Bytes`
        }
    }

    return undefined
}

export const flattenObject = (ob, prefix=false, result=null) => {
    result = result || {}
  
    if (prefix && typeof ob === 'object' && ob !== null && Object.keys(ob).length === 0) {
      result[prefix] = Array.isArray(ob) ? [] : {}
      return result
    }
  
    prefix = prefix ? prefix + '.' : ''
  
    for (const i in ob) {
      if (Object.prototype.hasOwnProperty.call(ob, i)) {
        if (
          typeof ob[i] === 'object' && 
          (Array.isArray(ob[i]) || Object.prototype.toString.call(ob[i]) === '[object Object]') && 
          ob[i] !== null
        ) {
          flattenObject(ob[i], prefix + i, result)
        } else {
          result[prefix + i] = ob[i]
        }
      }
    }
    
    return result
}

export const decodeHtmlCharCodes = (str) => {
    return str.replace(/(&#(\d+);)/g, (match, capture, charCode) => String.fromCharCode(charCode))
}
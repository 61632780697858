
export const currentStatus = (state) => {
    return state.status
}

export const currentToken = (state) => {
    return state.accessToken
}

export const currentUser = (state) => {
    return state.user
}

export const currentOrigin = (state) => {
    return state.origin
}

export const grcConfig = (state) => {
    return state.grcConfig
}
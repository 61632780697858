
import qs from 'qs'
import lerpApi from '@/api/lerpApi'
import { b64EncodeUnicode } from '@/helpers/utilTools'

export const getAccessTokenByHost = async ({ commit }) => {
    let errorCode = null
    
    try {
        const { data:resp } = await lerpApi.get('dt/login/valid-host')
        
        if (resp.api === 200) {
            const user = { name: resp.data.name, email: resp.data.email }
            const accessToken = resp.data.access_token
            const origin = resp.data.origin

            commit('loginUser', { user, accessToken, origin })
            return { status: true, mssg: resp.mssg }
        }
    } catch (e) {
        if (e.response) {
            console.log(`[API_CODE:${e.response.data.api}] ${e.response.data.mssg}`)
            errorCode = `ERROR_${e.response.data.api}`
        } else {
            console.log(`[HTTP_CODE:${e.code}] ${e.message}`)
            errorCode = `HTTP_${e.code}`
        }
    }

    commit('logoutUser')
    return { status: false, mssg: 'Could not get Access Token', errorCode: errorCode }
}

export const getAccessTokenByCredential = async ({ commit }, { email, password, grcToken }) => {
    let errorCode = 'ERROR_NULL'

    if (email && password) {
        try {
            const { data:resp } = await lerpApi({
                method: 'post',
                url: 'dt/login/valid-credential',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                data: qs.stringify({ credential: b64EncodeUnicode(`${email}:${password}`), grcToken })
            })

            if (resp.api === 200) {
                const user = { name: resp.data.name, email: resp.data.email }
                const accessToken = resp.data.access_token
                const origin = resp.data.origin

                commit('loginUser', { user, accessToken, origin })
                return { status: true, mssg: resp.mssg }
            }
        } catch (e) {
            if (e.response) {
                console.log(`[API_CODE:${e.response.data.api}] ${e.response.data.mssg}`)
                errorCode = `ERROR_${e.response.data.api}`
            } else {
                console.log(`[HTTP_CODE:${e.code}] ${e.message}`)
                errorCode = `HTTP_${e.code}`
            }
        }
    }

    commit('logoutUser')
    return { status: false, mssg: 'Could not get Access Token', errorCode: errorCode }
}

export const checkValidAccessToken = async ({ commit }, accessToken) => {
    let errorCode = 'ERROR_NULL'

    if (accessToken) {
        try {
            const { data:resp } = await lerpApi.get('dt/login/check-atoken', {
                params: { at: accessToken }
            })

            if (resp.api === 200) {
                const user = { name: resp.data.name, email: resp.data.email }
                const origin = resp.data.origin

                commit('loginUser', { user, accessToken, origin })
                return { status: true, mssg: resp.mssg }
            }
        } catch (e) {
            if (e.response) {
                console.log(`[API_CODE:${e.response.data.api}] ${e.response.data.mssg}`)
                errorCode = `ERROR_${e.response.data.api}`
            } else {
                console.log(`[HTTP_CODE:${e.code}] ${e.message}`)
                errorCode = `HTTP_${e.code}`
            }
        }
    }

    commit('logoutUser')
    return { status: false, mssg: 'Invalid Access Token', errorCode: errorCode }
}

export const loginUser = (state, { user, accessToken, origin }) => {
    if (typeof(user) === 'object' && typeof(accessToken) === 'string') {
        localStorage.setItem('accessToken', accessToken)
        state.user = user
        state.accessToken = accessToken
        state.origin = origin
        state.status = 'authenticated'
    }
}

export const logoutUser = (state) => {
    state.user = null
    state.accessToken = null
    state.origin = null
    state.status = 'not-authenticated'
    localStorage.removeItem('accessToken')
}
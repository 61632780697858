
export default () => ({
    companies: [],
    sidebarMenu: null,
    menuSelected: null,
    breadcrump: null,
    windowWidth: window.innerWidth,
    breakpoints: { 
        sm: 576, 
        md: 768, 
        lg: 992, 
        xl: 1200, 
        xxl: 1400 
    },
    responsive: 992
})